<template>
    <modal
        :id="id"
        title="Link TikTok Account"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <p>We require the permissions to manage your TikTok videos.</p>
            <p>
                You can always revoke access to your account by visiting
                <a
                    href="https://support.tiktok.com/en/safety-hc/account-and-user-safety/connect-to-third-party-apps"
                    target="_blank"
                >
                    https://support.tiktok.com/en/safety-hc/account-and-user-safety/connect-to-third-party-apps
                </a>
                , following instructions in the
                <b>How to view and remove connected third-party apps</b>
                section, and removing
                <b>ampifire</b>
                from the list.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Link
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectApp } from '@/mixins';
import { DitributionSites } from '@/types/Company';

const ConnectTikTokProps = Vue.extend({
    name: 'ConnectTikTok',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class ConnectTikTok extends mixins(
    ConnectTikTokProps,
    ConnectApp
) {
    id = 'connect-tiktok';

    onLink() {
        this.$http
            .get(
                `/connected_apps/oauth_tiktok/${this.companyId}?save_last_location=1`
            )
            .then(({ data }) => {
                this.authorize(data.data.loginUrl, DitributionSites.TikTok);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get TikTok authorization URL. Please try again later or contact support.'
                );
            });
    }
}
</script>
