<template>
    <modal
        :id="id"
        title="Link Vimeo Account"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <p>We require only the permission to upload and edit videos.</p>
            <p>
                You can always revoke access to your account by visiting
                <a href="https://vimeo.com/settings/apps" target="_blank">
                    https://vimeo.com/settings/apps
                </a>
                , and removing
                <strong>UBC News</strong>
                from the list.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Link
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectApp } from '@/mixins';
import { DitributionSites } from '@/types/Company';

const ConnectVimeoProps = Vue.extend({
    name: 'ConnectVimeo',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class ConnectVimeo extends mixins(
    ConnectVimeoProps,
    ConnectApp
) {
    id = 'connect-vimeo';

    onLink() {
        this.$http
            .get(
                `/connected_apps/authorize_vimeo_api/${this.companyId}?save_last_location=1`
            )
            .then(({ data }) => {
                this.authorize(data.data.loginUrl, DitributionSites.Vimeo);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get Vimeo authorization URL. Please try again later or contact support.'
                );
            });
    }
}
</script>
