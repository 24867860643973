<template>
    <v-skeleton-loader v-if="isLoading" loading type="article, actions" />
    <div v-else ref="container" class="google-maps-container"></div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { debounce, wait } from '@/utils/helpers';

import type { google } from 'google-maps/lib/types/types'; // for ESLint typings
import type { FullAddress } from '@/modules/GoogleMaps';

const AddressOnMapProps = Vue.extend({
    name: 'AddressOnMap',
    props: {
        address: {
            type: Object as PropType<FullAddress>,
            default() {
                return {
                    address: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: ''
                };
            }
        }
    }
});

@Component
export default class AddressOnMap extends AddressOnMapProps {
    $refs!: {
        container: HTMLDivElement;
    };

    defaultLocation: google.maps.LatLngLiteral = {
        lat: 37.0902,
        lng: 95.7129
    };

    map: InstanceType<typeof google.maps.Map> | null = null;
    marker: InstanceType<typeof google.maps.Marker> | null = null;

    isLoading = true;
    isDestroyed = false;

    debouncedMapUpdate = debounce(
        () => this.$nextTick(() => this.update()),
        350
    );

    @Watch('address')
    onAddressChanged() {
        this.debouncedMapUpdate();
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    mounted() {
        this.isDestroyed = false;
        this.setLoading();

        this.update();
    }

    destroyed() {
        this.isDestroyed = true;
    }

    async update() {
        return this.renderMap(await this.getLocation());
    }

    async getLocation() {
        return this.$maps
            .geocode(this.address)
            .then(({ results }) => this.extractLocation(results))
            .catch(() => this.defaultLocation);
    }

    extractLocation(results: google.maps.GeocoderResult[]) {
        const firstMatch = results[0];

        if (firstMatch) {
            return firstMatch.geometry.location;
        }

        return this.defaultLocation;
    }

    async renderMap(location: google.maps.LatLng | google.maps.LatLngLiteral) {
        if (!this.isDestroyed) {
            const google = await this.prepare();

            this.setPosition(google, location);
        }
    }

    async prepare() {
        const google = await this.$maps.getGoogle();

        this.setLoading(false);

        await wait(() => this.$refs.container);

        return google;
    }

    setPosition(
        google: google,
        position: google.maps.LatLng | google.maps.LatLngLiteral
    ) {
        if (this.map) {
            this.map.setCenter(position);

            this.map.setZoom(16);
        } else {
            this.map = new google.maps.Map(this.$refs.container, {
                zoom: 16,
                center: position
            });
        }

        this.setMarker(google, this.map, position);
    }

    setMarker(
        google: google,
        map: InstanceType<typeof google.maps.Map>,
        position: google.maps.LatLng | google.maps.LatLngLiteral
    ) {
        if (this.marker) {
            this.marker.setPosition(position);
        } else {
            this.marker = new google.maps.Marker({
                map,
                position
            });
        }
    }
}
</script>

<style lang="scss">
.google-maps-container {
    min-height: 250px;

    &:empty {
        display: none;
    }
}
</style>
